* {
  margin: 0;
  padding: 0;
}
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
h1 {
  font-size: 72px;
  font-family: sans-serif;
}
.container {
  display: flex;
  flex-direction: column;
}
.board {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 250px);
  grid-template-rows: repeat(3, 250px);
}
.board > div {
  padding: 5px;
  color: #fff;
  text-shadow: 2px 2px #ff0000;
  background-size: contain;
  border-radius: 5px;
  display: grid;
  font-family: sans-serif;
  font-size: 60px;
  font-weight: bold;
  transition: 0.3s all ease-in-out;
}
.btns {
  margin: 20px auto;
}
.btn {
  flex: 1 1 auto;
  margin-left: 20px;
  padding: 20px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  font-size: 16px;
  font-weight: bold;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  border-color: transparent;
  cursor: pointer;
}
.btn:nth-child(1) {
  margin-left: 0;
}
.btn:hover {
  background-position: right center; /* change the direction of the change here */
}
.btn-1 {
  color: #ffe287;
  background-image: linear-gradient(
    to right,
    #f6d365 0%,
    #fda085 51%,
    #f6d365 100%
  );
}
.btn-4 {
  color: #87a2cf;
  background-image: linear-gradient(
    to right,
    #a1c4fd 0%,
    #c2e9fb 51%,
    #a1c4fd 100%
  );
}
